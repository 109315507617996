import Head from "next/head";

export const PageHead = () => (
  <Head>
    <title>Runes Duel</title>
    <meta name="description" content="Epic card battles in the FWRC universe" />
    <link rel="icon" href="/favicon.ico" />
    <meta
      property="og:image"
      content="/cards/runes_cardgame_card_alchemiststifly_basic.jpg"
    />
    <meta
      name="twitter:image"
      content="/cards/runes_cardgame_card_alchemiststifly_basic.jpg"
    />
  </Head>
);
